//
// right-sidebar.scss
//

.right-bar {
    background-color: $bg-rightbar;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
    display: block;
    position: fixed;
    transition: all 200ms ease-out;
    width: $rightbar-width;
    z-index: 9999;
    float: right !important;
    right: -($rightbar-width + 10px);
    top: 0;
    bottom: 0;
    padding-bottom: 60px;

    .rightbar-title {
        // border-bottom: 2px solid $gray-300;
        padding: 25px 25px;
    }
    .right-bar-toggle {
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50%;
        margin-top: -4px;
    }
    .demo-img {
        height: 200px;
    }
}

// Rightbar overlay
.rightbar-overlay {
    background-color: rgba($dark, 0.55);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    z-index: 9998;
    transition: all .2s ease-out;
}

.right-bar-enabled {
    .right-bar {
        right: 0;
    }
    .rightbar-overlay {
        display: block;
    }
}

@include media-breakpoint-down(sm) {
    .right-bar {
        overflow: auto;
        .slimscroll-menu {
            height: auto !important;
        }
    }
}


.activity-widget{
    .activity-list{
        border-bottom: 1px solid $gray-200;
        padding: 12px 0px;
        &:last-child{
            border: none;
        }
    }
}

.upgrade-wid{
    .laptop-img{
        position: relative;
        bottom: 22px;
    }
}